<template>
  <CCard class="card-truckDetail"  v-bind:class="{hideDetails: hideDetails}">
    <CCardBody class="content-truckDetail">
      <div class="header-truckDetail">
        <i class="icon pi pi-chevron-left goBack" @click="closeCard()"></i>
        <div class="truckPlate">{{ truck.truck_plate }}</div>
        <a class="header-hideDetails" href="javascript:void(0)" @click="toggleDetails()" v-if="!hideDetails">Hide details</a>
        <a class="header-hideDetails" href="javascript:void(0)" @click="toggleDetails()" v-if="hideDetails">Show details</a>
      </div>
      <div class="truckInfo">
        <div
          class="collapse-header"
          @click="toggleCollapse('collapseTruckInfo')"
        >
          Truck info
          <i
            class="icon pi pi-chevron-up"
            v-bind:class="{ collapsed: collapseTruckInfo }"
          ></i>

          <!-- <transition name="fade">
            <i key=1  class="icon pi pi-chevron-up" v-if="!collapseTruckInfo"></i>
            <i key=2  class="icon pi pi-chevron-down" v-else="collapseTruckInfo"></i>
        </transition> -->
        </div>
        <CCollapse :show="!collapseTruckInfo">
          <CContainer>
            <CRow>
              <CCol sm="6">
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Order:</label>
                    <span>{{ truck.id_order }}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Plant:</label>
                    <span>{{ truck.plant_name }}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Carrier:</label>
                    <span>{{ truck.carrier_name }}</span>
                  </CCol>
                </CRow>
                <!-- <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Ship to:</label>
                    <span>{{truck.ship_to_name}}</span>
                  </CCol>
                </CRow> -->
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label class="inline">Planned route:</label>
                    <span>{{
                      truck.distance
                        ? convertDistanceToString(truck.distance*1000, truck.uom) 
                        : 'Unknown'
                    }}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label class="inline">Expected remaining route:</label>
                    <span>{{
                      travelInfo && travelInfo.pendingLength != 0
                        ?  convertDistanceToString(travelInfo.pendingLength, truck.uom)
                        : 'Press on "view remaining route" button to get the remaining route'
                    }}
                    <br>
                    {{ travelInfo && travelInfo.pendingTime != 0 ? moment().add(travelInfo.pendingTime,"seconds").format('DD/MM/YYYY HH:mm'):'' }}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <CButton class="btn" @click="viewRoute(truck)">
                      View remaining route
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="6">
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Status:</label>
                    <span
                      style="
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 0.5rem;
                        vertical-align: sub;
                      "
                      :style="lastEventColor"
                    ></span><span>{{ getEventFromEventName(truck.last_event) + ` (${moment(truck.last_event_timestamp).format("DD/MM/YYYY\u00a0HH:mm")})` }}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Delivery note no.:</label>
                    <span>{{ truck.dn_number }}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Delivery address:</label>
                    <span v-for="part of truck.ship_to_full.split(', ')" :key="part" style="display: block">
                      {{ part }}
                    </span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Forwarding agent:</label>
                    <span>{{ truck.forwarding_agent_name }}</span>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="element-truckDetail">
                    <label>Customer:</label>
                    <span>{{ truck.sold_to_name }}</span>
                  </CCol>
                </CRow>
                <CRow v-if="driverInfo">
                  <CCol sm="12" class="element-truckDetail">
                    <label>Driver:</label>
                    <div v-if="driverInfo && driverInfo.name">{{ driverInfo ? driverInfo.name : '' }}</div>
                    <div v-if="driverInfo && driverInfo.phoneNumber"><a style="color:#1D4370 !important" :href="`tel:${driverInfo.phoneNumber}`">{{driverInfo.phoneNumber}}</a></div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CContainer>
        </CCollapse>
      </div>
      <div class="routeHistory">
        <div
          class="collapse-header"
          @click="toggleCollapse('collapseRouteHistory')"
        >
          Route history
          <i
            class="icon pi pi-chevron-up"
            v-bind:class="{ collapsed: collapseRouteHistory }"
          ></i>
        </div>
        <CCollapse :show="!collapseRouteHistory">
          <CContainer>
            <DataTable :value="routeHistory" responsiveLayout="scroll">
              <Column field="id_order" header="Order"></Column>
              <Column field="dn_number" header="Delivery no."></Column>
              <!-- <Column field="distance" header="Planned route"></Column> -->
              <Column field="distance" header="Planned route">
                <template #body="item">
                  {{ convertDistanceToString(item.data.distance*1000, truck.uom)  }}
                  <!-- {{item.data.id_order}} -->
                </template>
              </Column>
              <Column field="real_distance" header="Real route">
                <template #body="item">
                  {{ item.data.real_distance ? convertDistanceToString(item.data.real_distance * 1000, truck.uom) : '(Press on view route)' }}
                  <!-- {{item.data.id_order}} -->
                </template>
              </Column>
              <Column field="arrivalTime" header="Arrival time">
                <template #body="item">
                  {{ item.data.arrivalTime ? moment(item.data.arrivalTime).format("DD/MM/YYYY HH:mm") : 'Unfinished' }}
                  <!-- {{item.data.id_order}} -->
                </template>
              </Column>
              <Column field="actions" header=" ">
                <template #body="item">
                  <a class="blue-link" href="javascript:void(0)" @click="viewRealRoute(item.data.truck_plate,item.data.dn_number)">View route</a>
                </template>
              </Column>
            </DataTable>
          </CContainer>
        </CCollapse>
      </div>
    </CCardBody>
    <div class="shadow-truckDetail"  :style="{'pointer-events': hideDetails ?'none': 'initial'}" @click="closeCard()" v-if="!hideDetails"></div>
  </CCard>
</template>

<script>
import * as gmaps from "../plugins/here";
import { store } from "../store/store";
import Vue from 'vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from 'moment';
export default {
  name: "TruckDetail",
  components: {
     DataTable,
        Column
      },
  props: {
    truck: Object,
  },
  computed: {
    marker() {
      return store.state.markers.filter(
        (x) => (x.truck_plate == this.truck.truck_plate)
      )[0];
    },
    lastEventColor() {
      var color = this.getColorFromEventName(this.truck.last_event);
      return { "background-color": color };
    },
  },
  data() {
    return {
      moment:moment,
      collapseTruckInfo: false,
      collapseRouteHistory: false,
      travelInfo: null,
      hideDetails: false,
      routeHistory: [],
      driverInfo: null,
    };
  },
  watch: {
    marker: {
      deep: true,
      immediate: true,
      handler: function () {
        this.updateTravelInfo();
      },
    },
  },
  created() {
    console.log("Created",this.truck.dn_number)
  },
  async mounted() {
    var self = this;
    //Get route history and real route
    //var realRoute = await Vue.prototype.$http.get(`api/geolocation/truck/route/${this.truck.truck_plate}/delivery/${this.truck.dn_number}`);
    console.log('dentro del truckdetail')
    var history = await Vue.prototype.$http.get(`api/geolocation/truck/route/${this.truck.truck_plate}/history/${this.truck.plant}`);
    history = history.data
   // realRoute = realRoute.data

    this.routeHistory= history.map((x) => {
     return {
      ...x,
      truck_plate: this.truck.truck_plate,
      real_distance: null,
      distance: parseFloat(x.distance).toFixed(2)
     }
    });

    var driverInfo = await Vue.prototype.$http.post(`api/geolocation/driver/info`, {
      "mode":"delivery",
      "deliveryId": self.truck.dn_number,
      "system": self.truck.system
    });
    self.driverInfo = driverInfo.data;
    console.log(self.driverInfo)
   
  },
  methods: {
    toggleCollapse(variable) {
      this[variable] = !this[variable];
    },
    viewRoute: (truck) => {
      gmaps.viewRoute(truck.truck_plate);
    },
    async viewRealRoute (truck_plate,dn_number)  {
      var self = this;
       gmaps.removeRoute();
          
      var realRoute = await Vue.prototype.$http.get(`api/geolocation/truck/${this.truck.plant}/route/${truck_plate}/delivery/${dn_number}`);
      var result = realRoute.data;
      var coords= [];
      if(result.travelToJobsiteRoute){
        coords.push(...result.travelToJobsiteRoute)
        gmaps.setRealRoute(result.travelToJobsiteRoute, "away");
      }
      if(result.returnToPlantRoute){
        coords.push(...result.returnToPlantRoute)

        gmaps.setRealRoute(result.returnToPlantRoute,"return");
      }

      if(coords.length ==0) return;

      coords.sort(function(a, b) {
          return moment.utc(a.position_timestamp).diff(moment.utc(b.position_timestamp))
      });
      var coordsList = coords.map((x) => {
        return { lat: x.position_latitude, lng: x.position_longitude };
      });
      // gmaps.setRealRoute(coords);

      gmaps.setPlantAndShipto(result.plant,result.shipto)

      // var realRoadRoute =await gmaps.getRouteWaypoints(coordsList)
      // gmaps.renderRealRoute(realRoadRoute)
       var distance = gmaps.distanceBetweenWaypoints(coordsList)
 
       
       self.routeHistory.forEach((x) => {
         if(x.dn_number == dn_number){
           x.real_distance = distance
         }
       })
      //  self.routeHistory = self.routeHistory.map((x) => {
      //    if(x.dn_number == dn_number){
      //      x.real_distance = distance.toFixed(2)
      //    }
      //    x.distance = parseFloat(x.distance).toFixed(2)
      //    return x;
      //  })

    },
    closeCard() {
      this.$emit("close", false);
    },
    updateTravelInfo() {
      this.travelInfo = gmaps.getTravelInfoTruck(this.truck.truck_plate);
    },
    toggleDetails(){
      this.hideDetails = !this.hideDetails
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: none;
  border: 0;
}
.shadow-truckDetail {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10100;
  display: none;
}
.card-truckDetail:hover{
  opacity: 1;
}
.card-truckDetail {
  opacity: 0.5;
  transition: opacity 0.2s;
  width: 30%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  &.hideDetails{
    opacity: 1;
    .truckInfo{
      display: none;;
    }
    .routeHistory{
      display: none;
    }
    .content-truckDetail{
      height: unset;
      padding-bottom: 0;
    }
  }
}
.content-truckDetail {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  // max-width: 35%;
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 10200;
  //   padding-top: 53.78px;
  padding-left: 0;
  padding-right: 0;

  overflow-y: auto;
}
.collapse-header {
  border-top: 2px solid #eeeeee;
  background: #f6f6f6;
  height: 56px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  text-align: left;
  line-height: 56px;
  padding-left: 32px;
  cursor: pointer;
  margin-bottom: 0rem;
  margin-top: 1rem;
  .icon {
    float: right;
    font-size: 24px;
    margin-right: 32px;
    margin-top: 16px;
    transition: all 0.3s linear;
    &.collapsed {
      transition: all 0.3s linear;
      transform: rotate(180deg);
    }
  }
}
.truckInfo {
  .element-truckDetail {
    padding-left: 32px;
    margin-top: 1.5em;
    label {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 0;
      display: block;
      &.inline {
        display: inline-block;
        margin-right: 0.5em;
      }
    }
  }
  
}

.header-truckDetail {
  margin-bottom: 20px;
  margin-left: 3rem;
  margin-right: 3rem;
  text-align: center;
  .goBack {
    font-size: 24px;
    cursor: pointer;
    border: 2px solid #eee;
    border-radius: 2px;
    padding: 0.5em;
    float: left;
    position: absolute;
    display: block;
  }
  .truckPlate {
    display: block;
    font-size: 30px;
    line-height: 56px;
    display: inline-block;
  }
  .header-hideDetails {
    float: right;
    font-size: 14px;
    line-height: 56px;
    color: #1D4370 !important;
    cursor: pointer;
  }
  
}
.blue-link{
    color: #1D4370 !important;
    cursor: pointer;
  }
.btn {
  border: 1px solid #d8d8d8;
  padding: 0.5em 2em;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  border-radius: 2px;
}

// Transition

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
