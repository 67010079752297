<template>
  <div class="height_100">
    <div class="map_parent">
      <div id="truck_map" ref="truck_map"></div>
      <div class="legend" :style="legendStyle" >
        <span><span class="legend-color" style="background: #6EA9CC;"></span>Travel to jobsite</span>
        <span><span class="legend-color" style="background: #6eccc4;"></span>Return to plant</span>
        <span><span class="legend-color" style="background: #166999;"></span>Expected remaining route</span>
       
    </div>
    <div style="position: fixed; top: 9px; left: 37.2%; z-index: 99; padding: 5px; text-align: center">
      <span><span class="legend-color"  v-if="fullScreenMap">
        <CButton @click="toggleFullScreen" class="center-map-btn">
          <CIcon name="cilArrowLeft" />
        </CButton>
        </span></span>
    </div>
    <div>
      
    </div>
    </div>
    <!-- <CButton
      
      size="sm"
      class="buttonDelete"
      @click="centerMap()"
    >Center map</CButton> -->
    <!-- <button @click="centerMap()">Center map</button> -->
 
  </div>
</template>

<script>
import * as gmaps from "../plugins/here";
import { store } from "../store/store";
import Vue from 'vue'

export default {
  name: "Map",
  components: {},
  props: {
    mode:String,
    fullScreenMap: Boolean
  },

  computed: {
    trucksState() {
      return store.state.trucks.filter(x=>x.mode =='dn');
    },
    statusFilter() {
      return store.state.filters.deliveryNoteStatus;
    },
    shouldCenterMap() {
      return store.state.centerMap;
    },
    legendStyle() {
      return {
        "display": this.mode != "mobile" ? "block" : "none",
      };
    },
    showInactiveFilter(){
      return store.state.filters.showInactive
    },
    checkedTrucks(){
      return store.state.trucks.filter(x=>x.mode =='dn' && x.checked).map(x=>x.truck_plate)
    },
    plantsLocations(){
      return store.state.plantLocations
    },
    showPlantsFilter(){
      return store.state.filters.showPlants
    },
    selectedPlantStore(){
      return store.state.selectedPlant
    },
  },
  watch: {
    trucksState(newData, oldData) {
      try{
      var addedMarkers = newData.filter(
        (x) => !oldData.some((y) => y.truck_plate == x.truck_plate)
      );  
      var addedVisibleMarkers = addedMarkers.filter(
        (x) => this.statusFilter.includes(x.last_event) || this.statusFilter.includes('all')
      );
       addedVisibleMarkers = addedVisibleMarkers.filter(
        (x) => !this.showInactiveFilter && this.isDeliveryActive(x.last_event, x.last_event_timestamp)
      );
      var toDeleteMarkers = oldData.filter(
        (x) => !newData.some((y) => y.truck_plate == x.truck_plate)
      );

      // console.log("toDeleteMarkers", toDeleteMarkers);
      for (var truck of toDeleteMarkers) {
        gmaps.removeTruckMarker(truck);
      }

      for (var newTruck of newData) {
        gmaps.setTruckMarker(
          { lat: newTruck.position_latitude, lng: newTruck.position_longitude },
          newTruck
        );
        if (this.mode == 'mobile') {
          this.checkedTrucks.push(newTruck.truck_plate)
        }
      }
        gmaps.calculateDNVisibility(this.statusFilter, this.showInactiveFilter, this.checkedTrucks);

      if (addedVisibleMarkers.length > 0 || this.shouldCenterMap) {
        //new trucks were added
        gmaps.centerMap();
        this.$store.commit("resetCenterMap");
      }
      
      }catch (e) {
        console.error(e)
      }
    },
    statusFilter(newData, oldData){
      gmaps.calculateDNVisibility(newData,this.showInactiveFilter, this.checkedTrucks, true);
    },
    showInactiveFilter(newData, oldData){
      gmaps.calculateDNVisibility(this.statusFilter,newData, this.checkedTrucks, true);
    },
    showPlantsFilter(newData, oldData){
      if(newData){
        gmaps.showPlants(this.plantsLocations);
        gmaps.centerMap();
        this.$store.commit("resetCenterMap");
      }else{
        gmaps.hideAllPlants();
        gmaps.centerMap();
        this.$store.commit("resetCenterMap");
      }
      
    },
    selectedPlantStore(newData, oldData){
     this.loadPlants();
      
    },
    checkedTrucks(newData, oldData){
      var newDataSorted = newData.map(x => x).sort();
      var oldDataSorted = newData.map(x => x).sort();
      if(newDataSorted.toString() != oldDataSorted.toString()){
        console.log('centerMap', newData, oldData)
        gmaps.centerMap();
      }

    }

  },
  data() {
    return {
      //infoCamion: {},
    };
  },
  created() {
    console.log("mapcreated")
  },

  async mounted() {
    // //https://stackoverflow.com/questions/43270159/how-to-watch-store-values-from-vuex
    await gmaps.loadMap(this.$refs["truck_map"], this.mode);
    store.commit('trigger')
    // gmaps.loadMap(this.$refs["truck_map"]).then(() => {
    //   gmaps.setTruckMarker(
    //     { lat: 40.43629, lng: -3.630887 },
    //     { truck_plate: "1234ABC", delivery_note_number: "P33333333333333" }
    //   );
    //   gmaps.setTruckMarker(
    //     { lat: 40.03629, lng: -3.130887 },
    //     { truck_plate: "4444YYY", delivery_note_number: "P22222222222222" }
    //   );
    //   gmaps.setTruckMarker(
    //     { lat: 41.53629, lng: -3.330887 },
    //     { truck_plate: "0987GHY", delivery_note_number: "P11111111111111" }
    //   );

    //   setTimeout(() => {
    //     gmaps.setTruckMarker(
    //       { lat: 41.43629, lng: -3.030887 },
    //       { truck_plate: "1234ABC", delivery_note_number: "P33333333333333" }
    //     );
    //   }, 5000);
    // });
  },

  methods: {
    centerMap: () => {
      gmaps.centerMap();
    },
    loadPlants: async () => {
      var plants = await Vue.prototype.$http.get(`api/geolocation/plants`);
      store.commit("setPlants", plants.data);
    },
    toggleFullScreen() {
      this.$emit("hideFullScreen", false);
    }
  },
};
</script>


<style>
@import "~@here/maps-api-for-javascript/bin/mapsjs-ui.css";
.map_parent {
  position: relative;
  /* min-height: 70vh; */
  height: 100%;
  max-height: 80vh;
  /* min-height: 80vh; */
}
#truck_map {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}
#truck_map {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
#truck_map:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.here_control_label {
  font-size: 2.5em;
  line-height: 1.5em;
}
.here_control_button > div{
  width: unset;
  padding-left: 1em;
  padding-right: 1em;
}
.H_btn{
  border-radius: 2px;
}


.H_ib_body{
  width: 35em;
}
.H_ib_content{
  line-height: normal;
}
.H_ib_content .content{
  /* white-space: nowrap; */
}
.route-bubble .H_ib_body {
  width: unset;
}
.height_100{
  height: 100%;
}


html:not([dir="rtl"]) .page-item:first-child .page-link{
  border-top-left-radius: 2px ;
border-bottom-left-radius: 2px ;
}
html:not([dir="rtl"]) .page-item:last-child .page-link{
  border-top-right-radius: 2px ;
border-bottom-right-radius: 2px ; 
}

.legend{
  position: fixed; top: 0px; right: 0px; z-index: 99; background: white; padding: 10px;
}
.legend .legend-color{
 width: 10px;height: 10px;display: inline-block;margin-right: 5px;
}
.legend > span {
  display: block;
}
</style>