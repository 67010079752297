<template>
  
  <div class="height_100">
  
    <div class="container-card height_100">
      <CCard class="height_100">
        <CCardBody style="max-height: 100vh;overflow: scroll;">     
          <!-- <a href="javascript:void(0)" @click="$router.push('/trck')" style="color:black !important">Go to Truck</a> -->
          <!-- <CButton class="btn" @click="$router.push('/trck')">
            Go to <b>Truck view</b>
          </CButton> -->
          <div style="display: flex; flex-wrap: nowrap; justify-content: space-between;">
            <CButton v-if="filterType=='plant' || filterType=='truck'" class="btn" @click="handleRouteChange">
              Go to <b>Truck view</b>
            </CButton>
            <div v-if="filterType=='plant' && availablePlants.length > 1" style="display: flex; flex-wrap: nowrap; align-items: center; margin-right: 10px; max-width: 70%;">
              <label style="margin-bottom: 0; margin-right: 10px;">Plant selected: </label>
              <!-- <CSelect
              style="margin-bottom: 0px;"
              :options="availablePlants"
              :value.sync="selectedPlant"
              @change="updateActivePlant()"
              /> !--><MultiSelect 
              style="margin-bottom: 0px; max-width: 70%;"
              :value="selectedPlants" 
              :options="availablePlants" 
              appendTo='body'
              optionLabel="" 
              placeholder=""
              @change="updateActivePlant"
              />
            </div>
            <CButton
              size="sm"
              class=""
              @click="fullScreen()"
            >
            <CIcon name="cilFullscreen" style="text-align:center; cursor: pointer;"  />
            </CButton>
          </div>
          <CDataTable
            class="custom-dt"
            :items="trucksFiltered"
            :fields="tableFields"
            :items-per-page="itemsPerpage"
            @row-clicked="rowClicked"
            @filtered-items-change="filteredItemsChange"
            hover
            sorter
            :sorterValue="{ column: 'truck_plate', asc: true }"
            pagination
            column-filter
>
  
          <template #select_truck-filter>
        <CInputCheckbox
          style="padding-left: 2rem"
          class="truck-checkbox truck-checkbox-filter"
          @update:checked="triggerTruckAll"
        />
      </template>
          <template #select_truck="{ item }">
              <td class="action-column">
                <CInputCheckbox 
                  class="truck-checkbox"
                  :data-truckplate=item.truck_plate
                  :checked.sync="item.checked"
                  @update:checked="triggerTruck(item)"
                />
              </td>
            </template>
            <template #truck_plate-filter="{ item }">
              <div class="multiselect-filter">
                <CInput 
                  class="multiselect-filter"
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.truck_plate"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #dn_number-filter="{ item }">
              <div class="multiselect-filter">
                <CInput 
                  class="multiselect-filter"
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.dn_number"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
           
            <template #last_event_timestamp-filter="{ item }">
              <div class="multiselect-filter">
                <CInput 
                  class="multiselect-filter"
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.status_date"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #plant_name-filter="{ item }">
              <div class="multiselect-filter">
                <CInput 
                  class="multiselect-filter"
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.plant"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #ship_to_full-filter="{ item }">
              <div class="multiselect-filter">
                <CInput 
                  class="multiselect-filter"
                  style="align-items: center; text-align: center; min-width: 160px"
                  v-model="deliveryFilters.ship_to_full"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #sold_to_name-filter="{ item }">
              <div class="multiselect-filter">
                <CInput 
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.sold_to_name"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #carrier_name-filter="{ item }">
              <div class="multiselect-filter">
                <CInput                   
                  style="align-items: center; text-align: center;"
                  v-model="deliveryFilters.carrier_name"
                  @keydown.enter="handleEnter()"
                />
              </div>
            </template>
            <template #vehicle_type-filter="{item}">
              <MultiSelect class="multiselect-filter" 
              v-model="deliveryFilters.vehicle_type" 
              :options="vehicleTypeSelect" 
              appendTo='body'
              optionLabel="vehicle_type" 
              placeholder=""
              @change="handleEnter"
              />
            </template>
            <template #haulier_type-filter="{item}">
              <MultiSelect class="multiselect-filter" v-model="deliveryFilters.haulier_type" 
              :options="contTypeSelect" 
              appendTo='body'
              optionLabel="haulier_type" 
              placeholder=""
              @change="handleEnter"
               />
            </template>
            <template #iut-filter="{item}">
              <MultiSelect class="multiselect-filter" v-model="deliveryFilters.iut" 
              :options="iutSelect" 
              appendTo='body'
              optionLabel="iut" 
              placeholder=""
              @change="handleEnter"
              />
            </template>
            <template #material_type-filter="{item}">
              <MultiSelect class="multiselect-filter" v-model="deliveryFilters.material_type" 
              :options="materialCategorySelect" 
              appendTo='body'
              optionLabel="material_type" 
              placeholder=""
              @change="handleEnter"
              />
            </template>
            <template #last_event-filter="{item}">
              <MultiSelect class="multiselect-filter" style=" max-width: 150px;" v-model="deliveryFilters.last_event" 
              :options="statusSelect" 
              appendTo='body'
              placeholder="" 
              @change="handleEnter">
              <template #value="slotProps">
                  <span class="" v-for="option of slotProps.value" :key="option.value">
                      <!-- <div>{{option.name}}</div> -->
                      <span
                      style="
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 0.5rem;
                        vertical-align: sub;
                      "
                      :style="getBackgroundColor(option.color)"
                    ></span>
                  </span>
                  <template v-if="!slotProps.value || slotProps.value.length === 0">
                      No selection
                  </template>
              </template>
              <template #option="slotProps">
                <span
                      style="
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 0.5rem;
                        vertical-align: sub;
                      "
                      :style="getBackgroundColor(slotProps.option.color)"
                    ></span><span>{{ slotProps.option.name}}</span>
        <!-- <div class="country-item">
            <img src="../../assets/images/flag_placeholder.png" :class="'flag flag-' + slotProps.option.code.toLowerCase()" />
            <div>{{slotProps.option.name}}</div>
        </div> -->
    </template></MultiSelect>
            </template>
          <template #truck_plate="{ item }">
              <td style="vertical-align: top;">
                <span
                  style="color: #1D4370;"
                >
                  {{ item.truck_plate }}
              </span>
              </td>
            </template>
            <template #ship_to_full="{ item }">
              <td style="min-width: 170px">
                <span v-for="part of item.ship_to_full.split(', ')" :key="part" style="display: block">
                  {{ part }}
                </span>
              </td>
            </template>
          <template #vehicle_type="{ item }">
              <td>
                
                  {{ item.vehicle_type || "" }}
              </td>
            </template> 
            
          <template #haulier_type="{ item }">
              <td>
                
                  {{ item.haulier_type || "" }}
              </td>
            </template>  
             
          <template #iut="{ item }">
              <td>
                
                  {{ item.iut || "" }}
              </td>
            </template>   
          <template #material_type="{ item }">
            <td>
              {{ getMaterialCategories(item.material_type).join(", ") || "" }}
            </td>
          </template> 
              <template #last_event="{ item }">
              <td style="vertical-align: top;">
                <span
                      style="
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 0.5rem;
                        vertical-align: sub;
                      "
                      :style="lastEventColor(item)"
                    ></span><span>{{ getEventFromEventName(item.last_event)}}</span>
              </td>
            </template>
            <!-- <template #last_event-filter="{item}">
              <input class="form-control form-control-sm test" 
              @input="e => setLastEventFilter(e)" />      
            </template> -->
            <template #last_event_timestamp="{item}">
              <td>
              {{moment(item.last_event_timestamp).format("DD/MM/YYYY\u00a0HH:mm") }}
            </td>
            </template>
            <template #actions="{ item }">
              <td class="action-column">
                <i class="icon pi pi-chevron-right"></i>
              </td>
            </template>
            <!-- <template #actions="{ item }">
              <td class="action-column">
                <CButton
                  color="primary"
                  class="btn btn-info focus-truck-btn"
                  @click="toggleDetails(item)"
                >
                  {{ Boolean(item._toggled) ? "Hide details" : "Show details" }}
                </CButton>
              </td>
            </template>
            <template #details="{ item }">
              <CCollapse
                :show="Boolean(item._toggled)"
                :duration="collapseDuration"
              >
                <CCardBody>
                  <CRow>
                    <CCol sm="12">
                      <CCard>
                        <CCardHeader> Details </CCardHeader>
                        <CCardBody>
                          <CRow>
                            <CCol sm="6">
                              <CInput
                                label="Forwarding agent"
                                :value="item.forwarding_agent_name"
                                disabled
                              />
                            </CCol>
                            <CCol sm="6">
                              <CInput
                                label="Carrier"
                                :value="item.carrier_name"
                                disabled
                              />
                            </CCol>
                          </CRow>
                        </CCardBody>
                        <CCardFooter>
                          <CButton
                            type="button"
                            color="primary"
                            class="btn btn-info focus-truck-btn"
                            @click="focusTruck(item)"
                          >
                            Focus truck
                          </CButton>
                          <CButton
                            type="button"
                            color="primary"
                            class="btn btn-info focus-truck-btn"
                            @click="viewRoute(item)"
                          >
                            View route
                          </CButton>
                        </CCardFooter>
                      </CCard>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCollapse>
            </template> -->
          </CDataTable>
          <CPagination
          :pages="totalPages"
          :active-page="currentPage"
          @update:activePage ="handlePagination"
        />
          <div style="display: flex;justify-content: flex-end;">
            <CInputCheckbox 
            class="truck-checkbox"
            style="margin-right: 10px;"
            label="Show inactive trucks"
            :checked.sync="showInactive"
            @change="showInactiveChange()"
            />
            <CInputCheckbox
            class="truck-checkbox" 
            style="margin-right: 10px;"
            label="Show all plants on map"
            :checked.sync="showPlants"
            @change="showPlantsChange()"
            />
          </div>
          
        </CCardBody>
      </CCard>
    </div>
    <transition name="slide">
      <TruckDetail 
      v-if="showTruckDetail"
       @close="closeTruckDetail"
       v-bind:truck="truckDetail"
        />
    </transition>
  </div>
</template>

<script>
import TruckDetail from "./TruckDetail.vue";
import * as gmaps from "../plugins/here";
import { store } from "../store/store";
import * as Socket from "../plugins/socket_client";
import MultiSelect from 'primevue/multiselect';
import moment from 'moment';
import Vue from "vue";
export default {
  name: "Trucks",
  components: { TruckDetail,MultiSelect
       },
  props: {totalPages: Number, currentPage: Number, deliveryFilters: Object, vehicleTypeSelect: Array, contTypeSelect: Array, iutSelect: Array, materialCategorySelect: Array},
  computed: {
    trucksState() {
      return store.state.trucks.filter(x=>x.mode =='dn');
    },
    statusFilter() {
      return store.state.filters.deliveryNoteStatus;
    },
    trucksFiltered(){
      var trucks = this.trucksState;
      // if(!this.showInactiveFilter){
      //   trucks = trucks.filter(x=>this.isDeliveryActive(x.last_event, x.last_event_timestamp));
      // }
      // if(this.selectedVehicleType.length > 0){
      //   trucks = trucks.filter(x=>this.selectedVehicleType.includes(x.vehicle_type));
      // }
      // if(this.selectedContType.length > 0){
      //   trucks = trucks.filter(x=>this.selectedContType.includes(x.haulier_type));
      // }
      // if(this.selectedIut.length > 0){
      //   trucks = trucks.filter(x=>this.selectedIut.includes(x.iut));       
      // }
      // if(this.selectedStatus.length > 0){
      //   trucks = trucks.filter(x=>this.selectedStatus.map(y=>y.value).includes(x.last_event));
      // }
      // if(this.selectedMaterialCategory.length > 0){
      //   trucks = trucks.filter(x=>this.getMaterialCategories(x.material_type).some(cat => this.selectedMaterialCategory.includes(cat)));
      // }
      // .slice(parseInt(this.currentPage.toString() + '0') ,this.currentPage + 10)
      trucks = trucks.filter(x=>x.show)
      // console.log(this.selectedStatus)
      // if(this.lastEventFilter){
      //   trucks = trucks.filter(x=>this.getEventFromEventName(x.last_event).toLowerCase().includes(this.lastEventFilter.toLowerCase()));
      // } 
      return trucks;
    },
    showInactiveFilter(){
      return store.state.filters.showInactive
    },
    availablePlants(){
      return store.state.availablePlants
    },
    selectedPlantStore(){
      return store.state.selectedPlant
    },
    filterType(){
      return store.state.filterType
    },
    checkedTrucks(){
      return store.state.trucks.filter(x=>x.mode =='dn' && x.checked).map(x=>x.truck_plate)
    },
    selectedPlants() {
      return store.state.selectedPlants;
    },
    tableFields(){
      var tableFields = [
        {
          key: "select_truck",
          label: "",
          sorter: false,
          filter: false,
        },
        {
          key: "truck_plate",
          label: "Truck plate",
          sorter: true,
          filter: false,
        },
        {
          key: "dn_number",
          label: "Delivery note number",
          sorter: true,
          filter: false,
        },
        {
          key: "last_event",
          label: "Status",
          sorter: true,
          filter: false

          
        },
        {
          key: "last_event_timestamp",
          label: "Status date",
          sorter:true,
          filter: false
        },
        {
          key: "plant_name",
          label: "Plant",
          sorter: true,
          filter: false
        },
        {
          key: "ship_to_full",
          label: "Delivery address",
          sorter: true,
          filter: false

        },
        {
          key: "sold_to_name",
          label: "Customer",
          sorter: true,
          filter: false
        },
        {
          key: "carrier_name",
          label: "Carrier",
          sorter: true,
          filter:false
        },
        
        { key: "vehicle_type", label: "Vehicle type", sorter: true,
          filter: false, },
    { key: "haulier_type", label: "Cont type", sorter: true,
          filter: false, },
    { key: "iut", label: "IUT", sorter: true,
          filter: false, },
    { key: "material_type", label: "Material Type", sorter: true,
          filter: false, },{
          key: "actions",
          label: "",
          sorter: false,
          filter: false,
        },
      ];

      

      return tableFields

    },
    // vehicleTypeSelect(e){
    //   return this.vehicleTypeSelect.filter((value, index, self) => self.indexOf(value) === index && value != null)
    // },
    // contTypeSelect(){
    //   return this.contTypeSelect.filter((value, index, self) => self.indexOf(value) === index && value != null)
    // },
    // iutSelect(){
    //   return this.trucksState.map(x=>x.iut).filter((value, index, self) => self.indexOf(value) === index && value != null)
    // },
    statusSelect(){
      return this.getStatusList()
    },
    // materialCategorySelect(){
    //   return this.trucksState.flatMap(x=>this.getMaterialCategories(x.material_type)).filter((value, index, self) => self.indexOf(value) === index && value != null);
    // },
  },

  data() {
    return {
      resultTable: [],
      // tableFields: ,
      collapseDuration: 0,
      collapse: true,
      showTruckDetail: false,
      truckDetail: null,
      lastEventFilter: null,
      showInactive:false,
      showPlants:false,
      selectedPlant:null,
      moment:moment,
      filteredItems:null,
      selectedVehicleType: [],
      selectedContType:[],
      selectedIut:[],
      selectedStatus:[],
      selectedMaterialCategory:[],
      materialTypeCategories: {
        ASP: ['BCS'],
        AGG: ['SAN', 'LND', 'LYT', 'DRY'],
        Others: ['MER', 'MIS', 'COM'],
        Recycle: ['REC']
      },
      itemsPerpage: 10,
      
    };
  },
  created() {},

  watch: {
    selectedPlantStore(newData, oldData) {
      if(newData != this.selectedPlant){
        this.selectedPlant = newData
        if (this.selectedPlant != null && this.currentPage == 1) {
          store.commit("setSelectedPlants", this.selectedPlant);
          this.$emit('loading', true)
          this.$emit("getDropdownValues")
          this.getDeliveryPagination()
        }
      }
    },
  

  },
  async mounted() {
    this.showInactiveChange();
    this.showPlantsChange();
    this.selectedPlant = this.$store.state.selectedPlant
    if (this.selectedPlants.length == 0) {
      store.commit("setSelectedPlants", this.selectedPlant);
      
    }
    store.commit('setFiltering', false)
  },

  methods: {
    lastEventColor(truck) {
      var color = this.getColorFromEventName(truck.last_event);
      return { "background-color": color };
    },
    handleRouteChange() {
      this.$router.push('/trck');
      this.$store.state.trucks = [];
      this.$store.state.markers = [];
      Socket.leaveRooms();
      this.$store.state.dnEvent = false;
    },  
    async handleEnter() {
      this.$emit('loading', true)
      await this.$emit('updateCurrentPage', 1);
      Vue.prototype.$http.post('/api/delivery/truck/' + this.itemsPerpage + '/' + this.currentPage, {
        filters: this.deliveryFilters,
        isActive: store.state.filters.showInactive,
        plant: this.selectedPlants
      })
      .then(res => {
        store.commit('setTrucksDn', res.data);
        store.commit('setFiltering', true);
        this.$emit('updateTotalPages', Math.ceil(store.state.totalTrucks / 10))
        this.$emit('loading', false)
      }).catch((e) => {
        this.$emit('loading', false)
      })
    },
    getDeliveryPagination() {
      console.log(this.selectedPlants)
      Vue.prototype.$http.post('/api/delivery/truck/' + this.itemsPerpage + '/' + this.currentPage, {
        isActive: store.state.filters.showInactive,
        filters: this.deliveryFilters,
        plant: this.selectedPlants
      }).then(res => {
          store.commit('setTrucksDn', res.data)
        
          this.$emit('updateTotalPages', Math.ceil(store.state.totalTrucks / this.itemsPerpage))
          

      })
      this.$emit('loading', false)
    },
    getBackgroundColor(color){
      return { "background-color": color };
    },
    focusTruck: (truck) => {
      gmaps.focusTruck(truck.truck_plate);
    },
    viewRoute: (truck) => {
      gmaps.viewRoute(truck.truck_plate);
    },
    toggleDetails(item) {
      item._toggled = !item._toggled;
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    openDetails(item) {
      this.truckDetail = item;
      this.showTruckDetail = true;
      this.$emit("fullScreenMap", true);
      this.$emit("openedDetails", true);
    },
    fullScreen() {
      this.$emit("fullScreenMap", true);
    },
    rowClicked(item, index, column_name, event) {

      //  console.log("rowClicked", item, index, column_name, event);
      if(column_name != 'select_truck')
        this.openDetails(item)
      // console.log("rowClicked", item, index, column_name, event);
    },
    
    filteredItemsChange(items) {
      this.filteredItems = items;
    },
    closeTruckDetail() {
      this.showTruckDetail = false;
      this.$emit("fullScreenMap", false);
      this.$emit("openedDetails", false);
    },
    setLastEventFilter(e) {
      var value= e.target.value;
     this.lastEventFilter = value//.split(' ').join('');
    },
    showInactiveChange(){
      store.commit("showInactive", this.showInactive);
      let self = this;
      if (this.selectedPlantStore != null) {
        this.$emit('truck-filter', { currentPage: self.currentPage, perPage:self.itemsPerpage})
        this.$emit('getDropdownValues')
      }

    },

    showPlantsChange(){
      store.commit("showPlants", this.showPlants);
    },
    updateActivePlant(selectedPlant){
      //Socket.setPlant(this.selectedPlant);
      //this.$emit('updateCurrentPage', 1);
      store.commit("setSelectedPlants", selectedPlant.value);
      this.$emit("updateCurrentPage", 1)
      this.$emit("truck-filter", {currentPage: 1, perPage: this.itemsPerpage})
      
    },
    triggerTruck(item, checked,obj){
      
      if (item.checked) {
        Socket.deliveryChecked({dn_number: item.dn_number, plant: item.plant})

      } else {
        Socket.deliveryUnchecked({dn_number: item.dn_number, plant: item.plant})
      }
      gmaps.calculateDNVisibility(this.statusFilter, this.showInactiveFilter, this.checkedTrucks);
    },
    handlePagination(item) {
      let self = this;
      this.$emit('updateCurrentPage', item)
      this.$emit('truck-filter', { currentPage: item, perPage:self.itemsPerpage})
    },
    async triggerTruckAll(checked,obj){
      var payload ={
        checked: checked,
        filteredItems: this.filteredItems
      }
      payload.currentPage = this.currentPage
      payload.mode = "dn"
      this.$emit('loading', true)

      if (checked) {
        Vue.prototype.$http.post('/api/delivery/truck/' + 10 + '/' + this.currentPage, {
        isActive: store.state.filters.showInactive,
        triggerAll: payload.checked,
        filters: this.deliveryFilters,
        plant: this.selectedPlants
      }).then(async res => {
        // store.commit('setTrucksDn', res.data, {triggerAll: true})
        payload.trucks = res.data;
       
        this.$emit('updateTotalPages', Math.ceil(store.state.totalTrucks / 10)) 
        store.commit("triggerTruckAll", payload);
          
        Socket.checkAllDeliveries(res.data.map(dn => ({ dn_number: dn.dn_number, plant: dn.plant })));
          this.$store.state.dnEvent = true;
        
      }).finally(() => {
        this.$emit('loading', false)

      })
      } else {
        store.commit("untriggerAll", payload);
        //store.commit("reset")
        
        Socket.leaveRooms()
        this.$emit('loading', false)
      }
     
       
    },
    getMaterialCategories(materialType) {
      const types = materialType.split(",");
      const categories = types.map(type => Object.keys(this.materialTypeCategories).find(cat => this.materialTypeCategories[cat].includes(type)));
      return categories.filter((value, index, self) => self.indexOf(value) === index && value != null);
    }
  },
};
</script>

<style>
.truck-checkbox input{
  position: initial;
  height: 25px !important;
  width: 25px !important;
}
.truck-checkbox-filter input{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.truck-checkbox > .form-check-label{
  vertical-align: super;
  padding-left: 5px;
}

</style>

<style lang="scss" scoped>
.multiselect-filter{
  margin-top: 20px;
  margin-bottom: 20px;
  height: 48px;
  max-width: 120px;
}
.btn {
  border: 1px solid #d8d8d8;
  padding: 0.5em 2em;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  border-radius: 2px;
}

.focus-truck-btn {
  white-space: nowrap;
  margin: 2px;
  padding: 5px;
  /* padding-top: 0px;
padding-bottom: 0px;
padding-left: 5px;
padding-right: 5px; */
}
.action-column {
  // display: flex;
}
.table th,
.table td {
  vertical-align: middle;
  overflow: auto
}

::v-deep .custom-dt {
  input {
    border-radius: 2px;
    border: 1px solid #d8d8d8;
    box-shadow: none;
    height: 48px;
  font-size: 16px;

  }

  thead {
    font-weight: 700;
    font-size: 14px;
    color: #888;
  }

  table thead tr th {
   
    border-bottom: none;
    
    input{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  table thead tr:first-child th{
    border-top: none;
  }

  td {
    color: black;
  }
  tr{
    cursor: pointer;
  }
}
::v-deep .container-card {
  .card {
    border: none !important;
    border-radius: 0 !important;
  }
}


.slide-enter-active,
    .slide-leave-active
    {
        transition: transform 250ms ease;
        ::v-deep .shadow-truckDetail{
          // transition: transform 1s ease;
          transition: all 250ms ease;
          opacity: 0.5;
          width: 400vw;
        }
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 250ms ease-in 0s;
        ::v-deep .shadow-truckDetail{
          transition: all 250ms ease-in 0s;
          opacity: 0;
        }
    }



</style>
